<template>
  <div class="navbar">
    <!-- <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" /> -->

    <!-- <breadcrumb class="breadcrumb-container" /> -->
    <div class="left-menu" v-if="userInfo.userType=='agent'?false:(userInfo.userType=='adv'?false:true)">
      <img src="../../assets/APP_logo.png" class="user-avatar">
      <span>{{userInfo.schoolName}}</span>
    </div>
    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar"> -->
          <img src="../../assets/APP_logo.png" class="user-avatar">
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="toHome()">
            首页
          </el-dropdown-item>
          <!-- <a target="_blank" href="#">
            <el-dropdown-item>Github</el-dropdown-item>
          </a> -->
          <!-- <a target="_blank" href="#">
            <el-dropdown-item>Docs</el-dropdown-item>
          </a> -->
          <el-dropdown-item divided v-if="Status == 'school' ? true : false" @click.native="changeCampus">
            <span style="display:block;">切换校区</span>
          </el-dropdown-item>

          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>
      <div class="userName">

        <el-tooltip class="item" style="margin-right: 20px;" effect="dark" placement="bottom">
          <i class="el-icon-service iconSize"></i>
          <div slot="content">
            <span>{{agentAdmin.name}}</span>
            <br />
            <span>{{agentAdmin.tel}}</span>
            <br />
            <el-image :src="agentAdmin.pic" style="width: 80px;height: 80px;" slot="reference"></el-image>
          </div>
        </el-tooltip>

        <!-- <i class="el-icon-s-home iconSize" @click="toHome()"></i> -->
        <el-tooltip class="item" effect="dark" placement="bottom" v-if="studentApplyNum + parentApplyNum > 0 ">
          <i class="el-icon-bell iconSize">
            <span
              style="   display: inline-block; background: red; padding: 0 4px;margin: 0 0 0 6px;border-radius: 10px;color: white;">{{parentApplyNum + studentApplyNum}}
            </span>
          </i>
          <div slot="content">
            <span class="itemm" v-if="studentApplyNum > 0 " @click="xstz(1)"> 您有{{studentApplyNum}}条学生申请待处理</span>
            <br />
            <span class="itemm" v-if="parentApplyNum > 0" @click="xstz(2)"> 您有{{parentApplyNum}}条家长申请待处理</span>
          </div>
        </el-tooltip>

        <i v-else class="el-icon-bell iconSize"></i>


        <!--            <el-tooltip >
              <div slot="content">
                <div v-for="(item,index) in scope.row.tealist" :key="index">
                    <div>  {{item.insert_time}} - {{item.over_time}}
                      <span style="margin-left:5px;"> {{item.teacher_name}}</span>
                    </div>
                </div>
              </div>
              <el-button type="text">{{scope.row.tnum}}</el-button>
          </el-tooltip> -->


        <i class="el-icon-s-custom iconSize"> {{userInfo.userName}}</i>

      </div>
      <el-dialog title="切换校区" :visible.sync="dialogFormVisible" top="20vh" :close-on-click-modal="false" width="400px">
        <el-form :model="create" ref="articleForm" label-position="right" label-width="100px">
          <el-form-item label="选择校区:">
            <el-select v-model="create.campus" clearable placeholder="请选择" style="width:200px;float:left">
              <el-option v-for="item in campusList" :key="item.ID" :label="item.Name" :value="item.ID">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button plain class="btn" style="margin-left:5px" @click="cancel()">取消</el-button>
        <el-button type="primary" class="btn" style="margin-left:10px" @click="confirm()">确定</el-button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  // import { mapGetters } from 'vuex'
  // import Breadcrumb from '@/components/Breadcrumb'
  // import Hamburger from '@/components/Hamburger'
  import {
    mapGetters
  } from "vuex";
  import {
    schoolSearchCampus,
    schoolChangeCampus,
    guaApplyelist
  } from "@/api/TeacherApi.js";
  import {
    newstuapply
  } from '@/api/user.js'
  import {
    agentAdmin,
  } from '@/api/agentApi.js'
  export default {
    data() {
      return {
        dialogFormVisible: false, // 弹窗
        campusList: [], // 弹窗
        create: {},
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        studentApplyNum: 0, //学生申请数量
        parentApplyNum: 0, //家长申请数量
        aaa: [],
        agentAdmin: {}, // 系统联系人
      }
    },
    mounted() {
      this.stuApplyList()
      this.AgentAdmin() // 获取系统联系人
      this.parentApplyList()
    },
    components: {
      // Breadcrumb,
      // Hamburger
    },
    computed: {
      ...mapGetters(["Status", "parApply", "stuApply"]),
      mapboxMap1(newData, oldData) {
        if (this.parApply == 10) {
          this.parentApplyList()
          this.$store.state.pApply = 0
        }
        if (this.stuApply == 10) {
          this.stuApplyList()
          this.$store.state.sApply = 0
        }
      }
    },
    watch: {
      mapboxMap1(newData, oldData) {}
    },
    methods: {
      AgentAdmin() { // 获取系统联系人
        agentAdmin().then(res => {
          this.agentAdmin = res.data.list;
        }).catch(err => {
          console.log(err)
        })
      },
      toggleSideBar() {
        //   this.$store.dispatch('app/toggleSideBar')
      },
      toHome() {
        let url = ''
        if (this.userInfo.userType == 'teacher' || this.userInfo.userType == 'teacherAgent') {
          url = '/teacherHome'
          this.$store.state.teaIndexs = 10
        } else if (this.userInfo.userType == 'agent') {
          url = '/AgentHome'
          this.$store.state.agentIndexs = 10
        } else if (this.userInfo.userType == 'school') {
          url = '/SchoolHome'
          this.$store.state.scIndexs = 10
        } else {
          url = '/'
        }
        localStorage.setItem('hpath', url)
        this.$router.push(url)
      },
      async logout() {
        localStorage.clear() // 清除session
        this.$router.push({
          path: '/login'
        })
        //   await this.$store.dispatch('user/logout')
        //   this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      },
      fcampusList() {
        schoolSearchCampus().then(res => {
          if (res.status.code == 1) {
            this.campusList = res.data;
          }
        }).catch(err => {
          this.loading = false;
          console.log(err)
        })
      },
      changeCampus() {
        this.fcampusList()
        this.dialogFormVisible = true
      },
      cancel() { // 点击取消
        this.dialogFormVisible = false
      },
      confirm() { // 点击确定
        if (this.create.campus == undefined || this.create.campus == "") {
          return false
        }
        const data = {
          ID: this.create.campus,
        }
        schoolChangeCampus(data).then(res => {
          if (res.status.code == 1) {
            localStorage.clear() // 清除session
            localStorage.setItem('userInfo', JSON.stringify(res.data)) // 存用户信息
            localStorage.setItem('token', JSON.stringify(res.data.token)) // 存token信息
            this.$store.commit('handleInfoCode', res.data.userType)

            let url = '/SchoolHome'
            this.$router.push(url)

            setTimeout(() => {
              location.reload()
            }, 100);
          } else {
            this.$message.error('切换失败')
          }
        }).catch(err => {
          console.log(err)
        })
      },
      stuApplyList() {
        if (this.Status == 'school' || this.Status == 'teacher') {
          // 学生申请列表数量
          const data = {
            page: 1, //	当前页数
            limit: 1 //	每页条数
          }
          newstuapply(data)
            .then((res) => {
              this.studentApplyNum = parseInt(res.data.CountNum) // 总条数
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      parentApplyList() {
        if (this.Status == 'school' || this.Status == 'teacher') {
          // 家长申请列表数量
          const data = {
            page: 1, //	当前页数
            limit: 1 //	每页条数
          }
          guaApplyelist(data)
            .then((res) => {
              this.parentApplyNum = parseInt(res.data.CountNum) // 总条数
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      xstz(type) { // 跳转页面
        localStorage.setItem('hpath', '/studentList' )
        if(type == 1){
         this.$router.push({
           name: 'applyList',
           params: {
           }
         })
        }else{
         this.$router.push({
           name: 'guaapplylist',
           params: {
           }
         })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }

    .breadcrumb-container {
      float: left;
    }

    .left-menu {
      float: left;
      margin-left: 20px;

      .user-avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-top: 5px;
      }

      span {
        float: right;
        margin-left: 10px;
        margin-top: 15px;
      }

    }

    .right-menu {
      float: right;
      height: 100%;
      line-height: 50px;

      &:focus {
        outline: none;
      }

      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: #5a5e66;
        vertical-align: text-bottom;

        &.hover-effect {
          cursor: pointer;
          transition: background .3s;

          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }

      .avatar-container {
        margin-right: 30px;

        .avatar-wrapper {
          margin-top: 5px;
          position: relative;

          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }

          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
          }
        }
      }

      .userName {
        float: left;

        .iconSize {
          font-size: 16px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .itemm:hover{
    text-decoration: underline;
    cursor: pointer;
  }
</style>
