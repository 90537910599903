<template>
  <el-container class="index-box">
    <el-aside width="asideWidth">
      <div class="logo">
        <img v-show="!isCollapse" src="./../assets/APP_logo.png" alt="ELementUI" width="50" height="50">
        <!--({{isUserType(userInfo.userType)}})-->
        <span style="color:white;margin-left:5px;margin-top: 10px;">视云格</span>
      </div>
      <sidebar :isCollapse="isCollapse"></sidebar>
    </el-aside>
    <el-container>
      <el-header style="padding:0;margin:0">
        <headers />
      </el-header>
      <el-main style="padding:0;margin:0;">
        <!-- <el-main style="background:#f0f2f5;padding:0;margin:0"> -->
        <!-- <breadcrumb class="breadcrumb"></breadcrumb> -->
        <router-view v-if="isRouterAlive" />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
  import sidebar from '@/components/Sidebar/sidebar'
  import headers from '@/components/Headers/header'
  import breadcrumb from '@/components/Breadcrumb/breadcrumb'
  export default {
    components: {
      sidebar,
      headers,
      breadcrumb
    },
    provide() {
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        userInfo: JSON.parse(localStorage.getItem("userInfo")),
        isRouterAlive: true
      }
    },
		mounted() {
			if (this.userInfo.userType == 'adv') {
				this.$router.push({name: 'adverList'})
			}
		},
    computed: {
      isCollapse() {
        return this.$store.state.isCollapse
      },
      asideWidth() {
        return this.$store.state.isCollapse ? 'auto' : '200px'
      },
      isUserType() {
        return function(userType) {
          switch (userType) {
            case "school":
              return "管理端"
              break
            case "teacher":
              return "教师端"
              break
            case "agent":
              return "代理端"
              break
            case "teacherAgent":
              return "代理端"
              break
            default:
              return "管理端"
          }
        }
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .index-box {
    overflow: hidden;

    height: 100%;
    min-height: 100%;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
      }
    }

    .el-aside {
      background-color: #20222A;
      // height: 100vh;
      height: 100%;

      .logo {
        background-color: #20222A;
        // color: rgba(255,255,255,.8);
        // text-align: center;
        // height: 60px;
        // padding-top: 16px;
        // font-size: 16px;
        // box-sizing: border-box;
        border-bottom: 1px solid gray;
        display: flex;
        justify-content: center;
      }
    }

  }

  .breadcrumb {
    // height: 30px;
    // background: white;
    // float: left;
  }
</style>
