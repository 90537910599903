<template>
    <!-- <div> -->
        <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item,index) in list" :key="index" :to="{path:item.path}">
                 {{item.meta.title}}
            </el-breadcrumb-item>
    </el-breadcrumb> -->

         <el-breadcrumb  separator="/" class="breadcurmb">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in list" :key="item.path">
        <span v-if="index==list.length-1" class="noCursor">{{ item.meta.title }}</span>
        <!-- <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
        <a v-else>{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
    <!-- </div> -->
</template>

<script>
export default {
  data () {
    return {
      // list:[],
      list: null
    }
  },
  watch: {
    $route () {
      this.bread()
    }
  },
  mounted () {

  },

  created () {
    this.bread()
  },
  methods: {
    bread () {
      // let matched = this.$route.matched.filter(item=>item.meta.title);
      // let first = matched[1];
      // // let first = matched[0];
      // // if(first&&first.meta.title!=='首页'){    // 我在这里是判断的是name，渲染的是name，但是可以使用其他的字段
      //     matched = [{path:"/",meta: { title: "" } }].concat(matched);
      // // }
      // this.list = matched;
      const matched = this.$route.matched.filter(item => item.meta.title && item.meta.title)
      // const first  = matched[0] && matched[0].name;
      // if(!(first.toLocaleLowerCase() === '/'.toLocaleLowerCase())){
      //     matched = [{path:'/',meta:{title:'首页'}}].concat(matched)
      // }
      this.list = matched
    }
    // handleLink(item){
    //     const {params} = this.$route
    //     const {path} = item;
    //     var PathRule = pathToRegexp.compile(path)
    //     if(redirect){
    //         this.$router.push(redirect)
    //         return
    //     }
    //     this.$router.push(PathRule(params))
    // }
  }
}
</script>

<style lang="scss" scoped>
    .breadcrumb-enter-active,
    .breadcrumb-leave-active{
        transition: all .5s;
    }
    .breadcrumb-enter,
    .breadcrumb-leave-active{
        opacity: 0;
        transform: translate(20px);
    }
    .breadcrumb-leave-active{
        position: absolute;
    }
    .el-breadcrumb{
        display:inline-block;
        font-size: 13px;
        line-height: 50px;
        .noCursor{
            color: #97a8be;
            cursor: text;
        }
    }
    .breadcurmb{
        display: flex;
         font-size: 15px;
        margin-left: 50px;
    }
</style>
